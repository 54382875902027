import React, { useEffect } from "react";

// seo
import Seo from "../components/Seo";

// utils
import { saveUTM } from "../utils";

// config
import config from "../config";

//components
import Header from "../components/WebinarLanding/Header/Header";
import SpeakerDetails from "../components/WebinarLanding/SpeakerDetails/SpeakerDetails";
import KeyTakeAways from "../components/WebinarLanding/KeyTakeWays/KeyTakeWays";
import SuccessStories from "../components/WebinarLanding/WebinarLpSuccessStories/SuccessStories";
import Benefits from "../components/WebinarLanding/Benefits/Benefits";
import OurSuccess from "../components/WebinarLanding/OurSuccess/OurSuccess";
import FloatingCta from "../components/WebinarLanding/FloatingCta/FloatingCta";

const ReversalWebinarLandingPage = props => {
  // save utm to local storage
  useEffect(() => {
    if (props.location.search) {
      saveUTM(props.location.search);
    }
  }, [props.location.search]);

  return (
    <div>
      <Seo
        title="Diabetes Webinar | Diabetes Reversal Program | Twin"
        description="Diabetes Webinar. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />
      <div className="sticky-top bgWhiteColor">
        <Header razorpayLink={config.RAZORPAY_LINK_LP} />
      </div>
      <div style={{ backgroundColor: "#f5f3f1" }}>
        <SpeakerDetails />
      </div>
      <div>
        <KeyTakeAways />
      </div>
      <div style={{ backgroundColor: "#F1F1EE" }}>
        <SuccessStories
          carouselId="webinarSalesSuccessStories"
          bgColor="webinarStories"
        />
      </div>
      <div>
        <Benefits />
      </div>
      <div style={{ background: "#F1F1EE" }}>
        <OurSuccess />
      </div>
      <FloatingCta razorpayLink={config.RAZORPAY_LINK_LP} />

      {/* Pixel Integration */}
      <iframe
        src="https://trk.y2k1.xyz/pixel?adid=61e7ffa3dbf0860904673ce8"
        scrolling="no"
        frameborder="0"
        width="1"
        height="1"
        title="pixelWebinarIFrame"
      ></iframe>
    </div>
  );
};

export default ReversalWebinarLandingPage;
